import axios from 'axios'
import { getFormData, getFormDataMultidimensional } from '@/utils/utils'


export default {
  setFrontend(params) {
    return axios.post(`/frontend/add`, getFormDataMultidimensional(params.data),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  getHomepageData() {
    return axios.get('/front/homepage')
  }
}
